import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";
function Index() {
  const [home_AI_tableIndex, sethome_AI_tableIndex] = useState(0);
  const [lastScrollTop, setlastScrollTop] = useState(0);
  const [headerFlag, setheaderFlag] = useState(true);
  const [ismobileFlag, setismobileFlag] = useState(false);

  useEffect(() => {
    console.log(window);
    var userAgent = navigator.userAgent;
    var mobileRegex =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    if (mobileRegex.test(userAgent)) {
      // 用户代理字符串中包含手机端关键字，表示是手机端设备
      console.log("这是手机端设备");
      setismobileFlag(true);
    } else {
      // 用户代理字符串中未包含手机端关键字，可能是桌面设备
      console.log("这不是手机端设备");
      setismobileFlag(false);
    }

    if (window.location.pathname === "/terms") {
      sethome_AI_tableIndex(4);
    } else if (window.location.pathname === "/privacy") {
      sethome_AI_tableIndex(3);
    }
    else if (window.location.pathname === "/joinus") {
      sethome_AI_tableIndex(2);
    }
    else if (window.location.pathname === "/about") {
      sethome_AI_tableIndex(1);
    }
    else {
      sethome_AI_tableIndex(0);
    }
  }, []);
  useEffect(() => {
    document.querySelectorAll(".home_AI")[0].scrollTop = 0;
  }, [home_AI_tableIndex]);
  const homeScroll = (e) => {
    if (
      e.target.scrollTop > lastScrollTop &&
      e.target.scrollTop - 10 > lastScrollTop
    ) {
      // 用户正在向下滚动
      setheaderFlag(false);
    } else if (
      e.target.scrollTop < lastScrollTop &&
      e.target.scrollTop < lastScrollTop - 10
    ) {
      // 用户正在向上滚动
      setheaderFlag(true);
    }
    if (e.target.scrollTop <= 0) {
      setheaderFlag(true);
    }
    setlastScrollTop(e.target.scrollTop);
  };
  return (
    <div className="home">
      <div
        className="home_AI"
        onScroll={(e) => {
          homeScroll(e);
        }}
      >
        <div className={headerFlag ? "home_AI_header" : "home_AI_headers"}>
          <img
            src={require("../../static/icon.png")}
            alt=""
            className="Atomgradient_icon"
            style={
              ismobileFlag
                ? {
                  left: "1.5rem",
                }
                : {
                  left: (window.innerWidth - 900) / 2,
                }
            }
            onClick={() => {
              sethome_AI_tableIndex(0);
              window.history.pushState(
                { path: window.location.pathname },
                "",
                "/"
              );
            }}
          />
          <div className="home_AI_header_table">
            <div
              className={home_AI_tableIndex === 1 ? "about abouts" : "about"}
              onClick={() => {
                sethome_AI_tableIndex(1);
                window.history.pushState(
                  { path: window.location.pathname },
                  "",
                  "/about"
                );
              }}
            >
              About
            </div>
            <br />
            <div
              className={home_AI_tableIndex === 2 ? "joinus joinuss" : "joinus"}
              onClick={() => {
                sethome_AI_tableIndex(2);
                window.history.pushState(
                  { path: window.location.pathname },
                  "",
                  "/joinus"
                );
              }}
            >
              Work
            </div>
            <br />
            <div
              className={home_AI_tableIndex === 3 ? "privacy privacys" : "privacy"}
              onClick={() => {
                sethome_AI_tableIndex(3);
                window.history.pushState(
                  { path: window.location.pathname },
                  "",
                  "/privacy"
                );
              }}
            >
              Privacy
            </div>
            <br />
            <div
              className={home_AI_tableIndex === 4 ? "terms termss" : "terms"}
              onClick={() => {
                sethome_AI_tableIndex(4);
                window.history.pushState(
                  { path: window.location.pathname },
                  "",
                  "/terms"
                );
              }}
            >
              Terms
            </div>

          </div>
        </div>

        <div className="zhezhao">
          {home_AI_tableIndex === 0 ? (
            <>
              <div className="zhezhao_title">
                <span>Announcing</span>
                <span>EchoStream</span>
              </div>
            </>
          ) : home_AI_tableIndex === 1 ? (
            <div className="zhezhao_about">
              <span>Gentle</span>
              <span>AI</span>
              <span>Better</span>
              <span>Life</span>
            </div>
          ) : (
            <>
              <div className="zhezhao_humanity">
                <span>Help</span>
                <span>us</span>
                <span>build</span>
                <span>AI</span>
                <span>That</span>
                <span>enrich</span>
                <span>humanity</span>
              </div>
              <p className="reveal">
                Join our team to work on next-generation AI breakthroughs
              </p>
            </>
          )}
        </div>
        <div className="home_AI_container">
          {home_AI_tableIndex === 0 ? (
            <div className="home_AI_containersdaigyi">
              <div>
                
                  EchoStream
                {" "}
                is the most powerful AI assistant that it can help you to echo your memories.
              </div>
              {/* <div style={{ marginTop: 10 }}>
              EchoStream is an AI reading agent currently in its growth phase. When you perform tagging operations in EchoStream, EchoStream will create unique memories for you. Currently its daily goal is to read a variety of internet content, with current interests focused on fields like artificial intelligence, society, science, humanities, history, and engineering.
              </div> */}
              {/* <iframe
                style={{ marginTop: 30, borderRadius: 12 }}
                width={"100%"}
                height="400"
                src="https://www.youtube.com/embed/4UVWAWQWAwg?si=zAHAx_18L3iG-WfT?enablejsapi=1&autoplay=1&"
                title="Quwanba"
                frameBorder="0"
                id="quwanbaVideo"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                autoPlay={1}
              ></iframe> */}

              <ul>
                <h1>Your records are yours.</h1>
                <li>
                EchoStream is an AI reading agent currently in its growth phase. When you perform tagging operations in EchoStream, EchoStream will create unique memories for you. Currently its daily goal is to read a variety of internet content, with current interests focused on fields like artificial intelligence, society, science, humanities, history, and engineering.
                </li>
                {/* <li>
                  Looking at the market, there is very large market potential
                  and extensive unmet user needs.
                </li>
                <li>
                  Looking at competition, the tourism market is very fragmented
                  and long-tailed, and the OTA platform is an oligopoly.
                </li> */}
                <h1>Your mind is unique.</h1>
                <li>
                EchoStream aims to evolve into your personal reading agent, reading a wide range of information for you daily, and providing briefings at any moment. It also offers unique recommendations after synthesizing your various requirements.
                </li>
                <h1>Your knowledge should last.</h1>
                <li>
                In the near future, EchoStream will accept your personalized inputs to complete its personalized learning, becoming your own personalized EchoStream. Just like a brain clone. As its name suggests, it echoes streaming information, managing memories for you.
                </li>
                {/* <li>
                  Chat (or interaction) and intent display are best displayed on
                  one screen, with display taking up the majority. What you
                  think is what you get must be integrated into one.
                </li>
                <li>
                  The user's intention is incomplete and needs improvement. The
                  dialogue (or interaction) cannot be recursive without limit.
                  It is necessary to guide the end when necessary.
                </li>
                <li>
                  Personalization is becoming more and more important, and it is
                  best to pre-embed the user’s Profile
                </li>
                <li>
                  Multi-modal support is necessary, that is, at least text and
                  images, preferably both must be available
                </li> */}
              </ul>
            </div>
          ) : home_AI_tableIndex === 1 ? (
            <div className="home_AI_containersdaigyi">
              <ul>
                <h1>AtomGradient Mission</h1>
                <li>
                  Become the world's leading intelligent service provider and
                  accelerate the arrival of people's high-quality and beautiful
                  life.
                </li>
              </ul>
              <h1>Team</h1>
              <div className="teamnember">
                <div className="teamPeo">
                  <img
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 40,
                      margin: "0 20px",
                    }}
                    src={require("../../static/teamImg/AlexWu.PNG")}
                    alt=""
                    srcset=""
                  />
                  Alex Wu
                </div>
                <div className="teamPeo">
                  <img
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 40,
                      margin: "0 20px",
                    }}
                    src={require("../../static/teamImg/guoba.jpeg")}
                    alt=""
                    srcset=""
                  />
                  Guoba
                </div>
                <div className="teamPeo">
                  <img
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 40,
                      margin: "0 20px",
                    }}
                    src={require("../../static/teamImg/XiWu.PNG")}
                    alt=""
                    srcset=""
                  />
                  Xi Wu
                </div>
                {/* <div className="teamPeo">徐启超</div> */}
              </div>
            </div>
          ) : home_AI_tableIndex === 2 ? (
            <div className="home_AI_containersdaigyi">
              <h1>Work at AtomGradient</h1>
              <div>
                We are a team of AI researchers and engineers on a mission to
                build AI products that can help humanity enrich their life
                better. We are driven by ambitious goals, fast execution, and a
                strong sense of urgency. Join us if you want to shape the next
                generation of AI models and products.
              </div>
              <h2>Open roles</h2>
              <div>
                We are always looking to hire the best people in the field.
                Apply using the links below if you are interested.
              </div>

              <div
                className="openFeishu"
                onClick={() => {
                  window.open(
                    "https://daxiang.feishu.cn/docx/S7JCdCvvnoTaxZxT9ZTckvXmn4e"
                  );
                }}
              >
                <span>AI Engineer & Researcher</span>
                <span style={{ marginTop: 5, color: "#929ca5" }}>
                  Beijing China
                </span>
              </div>

              <div
                className="openFeishu"
                onClick={() => {
                  window.open(
                    "https://daxiang.feishu.cn/docx/AGfJd2QYao7BnKxQefIc651qnog"
                  );
                }}
              >
                <span>Data Engineering & Web Crawling</span>
                <span style={{ marginTop: 5, color: "#929ca5" }}>
                  Beijing China
                </span>
              </div>

              <div
                className="openFeishu"
                onClick={() => {
                  window.open(
                    "https://daxiang.feishu.cn/docx/AxB7dNFRBowp0KxFcXXcgeMLnad"
                  );
                }}
              >
                <span>Full Stack Web Engineer</span>
                <span style={{ marginTop: 5, color: "#929ca5" }}>
                  Beijing China
                </span>
              </div>
              <div
                className="openFeishu"
                onClick={() => {
                  window.open(
                    "https://daxiang.feishu.cn/docx/GcVTdAGqJoxP7lxV3HwcnqcknBd"
                  );
                }}
              >
                <span>Infrastructure Engineer</span>
                <span style={{ marginTop: 5, color: "#929ca5" }}>
                  Beijing China
                </span>
              </div>

              <div
                className="openFeishu"
                onClick={() => {
                  window.open(
                    "https://daxiang.feishu.cn/docx/LluCd7J38o9U5pxN3kdcpSh6nEh"
                  );
                }}
              >
                <span>AI Talent Product</span>
                <span style={{ marginTop: 5, color: "#929ca5" }}>
                  Beijing China
                </span>
              </div>
            </div>
          ) : home_AI_tableIndex === 3 ? (
            <div className="home_AI_containersdaigyi">
              <h1>EchoStream's Privacy Policy</h1>
              <p>Last updated: January 5th, 2024</p>
              <br />
              <p>This privacy policy (“Policy”) describes how EchoStream (app) and <a href="https://atomgradient.com" target="_blank" rel="noopener">atomgradient.com</a> (“we”, “us”, or “our”) collects, uses, shares and protects information about you when you use our App, products and services (collectively, the “Services”).</p>
              <h1>Our Services</h1>
              <p>EchoStream App provides a text generation service. EchoStream is available via <a href="https://atomgradient.com" target="_blank" rel="noopener">atomgradient.com</a> and associated internet domains such as <a href="https://atomgradient.com" target="_blank" rel="noopener">atomgradient.com</a> and others.</p>
              <h1>Information EchoStream Collects</h1>
              <p>When you use our app, we may collect certain information automatically from your device. This information may include your IP address, device type, unique device identification numbers, browser-type, broad geographic location (e.g. country or city-level location), and other technical information. We may also collect information about how your device has interacted with our app, including the pages accessed and links clicked. Collecting this information enables us to better understand the visitors who come to our app, where they come from, and what content is of interest to them. We use this information for our internal analytics purposes and to improve the quality and relevance of our service.</p>
              <br />
              <p>If you create an account on our app, we will collect certain information from you, including your name, email address. We use this information to provide you with access to your account and to provide you with customer support.</p>
              <h1>Information EchoStream Does Not Collect</h1>
              <p>We do not knowingly collect any sensitive information from you, such as information about your race or ethnic origin, political opinions, religion, or health.</p>
              <h1>Sharing of Information</h1>
              <p>We do not sell, trade, or otherwise share your personal information with third parties, except as described in this Policy. We may share your personal information with the following types of third parties:</p>
              <ul>
                <li>Service providers. We may share personal information with service providers that perform certain services on our behalf. For example, we may use service providers to send email or other communications, process payments, or provide customer support.</li>
                <li>Analytics service providers. We may share anonymized usage information with analytics service providers that assist us in the improvement and optimization of our app. For example, <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener">Google Analytics</a>.</li>
              </ul>
              <h1>Third Party Services</h1>
              <p>We may use third party services, such as Application Programming Interfaces (APIs), to maintain and improve our services. For example, queries may transmitted to OpenAI API in order to serve requests. No personally identifiable information (PII) is transmitted unless required for continuity of service, for example sending email, processing payments, or providing customer support.</p>
              <br />
              <p>We may use site monitoring tools, such as Cloudflare, Sentry, and Umami, to collect anonymized information about usage. These tools may collect your IP address, device type, browser type, operating system, geographic location, and other technical information. We use this information to improve the quality and relevance of our app to our visitors.</p>
              <h1>Security</h1>
              <p>We take reasonable measures to protect the personal information that we collect from you. Data is stored securely on Azure Web Services. However, no security measure is perfect, and we cannot guarantee the security of your personal information.</p>

              <h1>Data Retention</h1>
              <p>We retain your personal information for as long as your account is active or as needed to provide you with our Services. You may opt out of data collection for AI data collection which would allows us to use your search data to improve our AI models in your settings page if you are logged into our platform. </p>
              <br />
              <p>If you delete your account, we will delete your personal information from our servers within 30 days. Please contact us at <a href="mailto:alex@atomgradient.com" target="_blank" rel="noopener">alex@atomgradient.com</a> to request deletion.</p>

              <h1>Changes to our Privacy Policy</h1>
              <p>We may change this Policy from time to time. If we make changes, we will post the revised Policy on our app and update the “Last updated” date above. We encourage you to review the Policy whenever you access or use our Services or otherwise interact with us to stay informed about our information practices and the choices available to you.</p>

              <h1>Contact Us</h1>
              <p>If you have any questions about this Policy, please contact us at <a href="mailto:alex@atomgradient.com" target="_blank" rel="noopener">alex@atomgradient.com</a></p>
            </div>
          )
            : (
              <div className="home_AI_containersdaigyi">
                <h1>EchoStream's Terms of Service</h1>
                <p>Last updated: January 5th, 2024</p>
                <br />
                <p>Please read these Terms of Service (“Terms”, “Terms of Service”) carefully before using the EchoStream app (the “Service”) operated by AtomGradient (“us”, “we”, or “our”).</p>
                <br />
                <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
                <br />
                <p>By accessing or using the Service you agree to be bound by these Terms. AtomGradient reserves the right, in its sole discretion, to restrict, suspend, or terminate your access to and use of the Service.</p>

                <h1>Our Services</h1>
                <p>EchoStream App provides a text generation service. EchoStream is available via <a href="https://atomgradient.com" target="_blank" rel="noopener">atomgradient.com</a> and associated internet domains such as <a href="https://atomgradient.com" target="_blank" rel="noopener">atomgradient.com</a> and others.</p>

                <h1>Acceptable Use</h1>
                <p>You must be at least 17 years old to use the Service. If you are under 18 you must have your parent or legal guardian’s permission to use the Service. You agree not to share, transfer, or sell your account. You are responsible for all activities on your account.</p>
                <br />
                <p>You agree not to use the Service in any way that causes, or may cause, damage to the Service or impairment of the availability or accessibility of the Service. You agree not to use the Service for unlawful, illegal, fraudulent or harmful activities, including but not limited to hate, harassment, violence, political manipulation, spam, or malware.</p>
                <br />
                <p>You agree not to conduct any systematic or automated data collection activities (including scraping, data mining, data extraction or data harvesting) on or in relation to the Service. Prohibited data collection includes, but is not limited to, using the Service as input into other services, apps, or databases. You agree not to republish any content generated by the Service without clearly citing the Service as well as the context associated with the content. Misrepresentation of the source of the content or the nature of its creation is strictly prohibited.</p>
                <br />
                <h1>User-Generated Content</h1>
                <p>The Service may allow you to upload, submit, store, send or receive content. You retain ownership of any intellectual property rights that you hold in that content. In short, what belongs to you stays yours.</p>
                <br />
                <p>When you upload, submit, store, send or receive content to or through the Service, you give us (and those we work with) a worldwide license to use, host, store, reproduce, modify, create derivative works (such as those resulting from translations, adaptations or other changes we make so that your content works better with the Service), communicate, publish, publicly perform, publicly display and distribute such content. The rights you grant in this license are for the limited purpose of operating, promoting, and improving our Service, and to develop new ones. Make sure you have the necessary rights to grant us this license for any content that you submit to our Services.</p>
                <br />
                <p>You understand that all information, data, text, software, music, sound, photographs, graphics, video, messages, tags, or other materials (“Content”), whether publicly posted or privately transmitted, are the sole responsibility of the person from whom such Content originated. This means that you, and not AtomGradient, are entirely responsible for all Content that you upload, post, email, transmit or otherwise make available via the Service. AtomGradient does not control the Content posted via the Service and, as such, does not guarantee the accuracy, integrity or quality of such Content. You understand that by using the Service, you may be exposed to Content that is offensive, indecent or objectionable. Under no circumstances will AtomGradient be liable in any way for any Content, including, but not limited to, any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the use of any Content posted, emailed, transmitted or otherwise made available via the Service.</p>
                <br />
                <p>You agree that you must evaluate, and bear all risks associated with, the use of any Content, including any reliance on the accuracy, completeness, or usefulness of such Content. In this regard, you acknowledge that you may not rely on any Content created by the Service or submitted to the Service.</p>
                <br />
                <p>You acknowledge and agree that AtomGradient may preserve Content and may also disclose Content if required to do so by law.</p>
                <br />
                <h1>Intellectual Property Rights</h1>
                <p>AtomGradient acknowledges and respects the intellectual property rights of all individuals and entities, and expects all users of the Service to do the same. As a user of the Service, you are granted access for your own personal, non-commercial use only. Any attempts to reverse engineer, copy, reproduce, or otherwise steal intellectual property from the Service will not be tolerated and may result in legal action.</p>
                <br />
                <p>AtomGradient takes all allegations of copyright infringement seriously and will respond promptly to any notices that comply with applicable laws and are properly provided to us. If you believe that your work has been used in a manner that constitutes copyright infringement, please email us at <a href="mailto:alex@atomgradient.com" target="_blank" rel="noopener">alex@atomgradient.com</a></p>

                <h1>Limitation of Liability</h1>
                <p>In no event shall AtomGradient, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>

                <h1>Disclaimer</h1>
                <p>Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>
                <br />
                <p>AtomGradient, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; nor d) the results of using the Service will meet your requirements.</p>

                <h1>Indemnification</h1>
                <p>You agree to defend, indemnify and hold harmless AtomGradient and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password; b) a breach of these Terms, or c) Content posted on the Service.</p>

                <h1>Changes</h1>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                <br />
                <p>After modification, we will post the revised Terms and update the “Last updated” date above. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please cease using the Service.</p>

                <h1>Contact Us</h1>
                <p>If you have any questions about these Terms, please contact us at <a href="mailto:alex@atomgradient.com" target="_blank" rel="noopener">alex@atomgradient.com</a>.</p>




              </div>
            )
          }
        </div>
        <div className="home_AI_footer">
          <img
            src={require("../../static/icon.png")}
            alt=""
            className="Atomgradient_icon_footer"
            style={
              ismobileFlag
                ? {
                  left: "1.5rem",
                }
                : {
                  left: (window.innerWidth - 900) / 2 - 40,
                }
            }
            onClick={() => {
              sethome_AI_tableIndex(0);
              window.history.pushState(
                { path: window.location.pathname },
                "",
                "/"
              );
            }}
          />
        </div>
      </div>
      <div className="video_header">
        {ismobileFlag ? (
          <div className="mobileBack"></div>
        ) : (
          <video
            src={require(`../../static/bg6zip.mp4`)}
            loop
            autoPlay
            muted
          ></video>
        )}
      </div>
    </div>
  );
}

export default Index;
